import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11cf509c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "users-in-role" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_orbis_card = _resolveComponent("orbis-card")!
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_tabs = _resolveComponent("b-tabs")!
  const _component_smart_trak_footer = _resolveComponent("smart-trak-footer")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    id: "edit-role-screen",
    title: _ctx.pageTitle + ' - ' + _ctx.state.roleEdit.name,
    full: "",
    "go-back": _ctx.goToRoleList
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_smart_trak_footer, { class: "role-footer" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "light",
            onClick: _ctx.goToRoleList
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_b_button, {
            variant: "primary",
            disabled: _ctx.state.saving,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')) + " ", 1),
              (_ctx.state.saving)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    small: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.state.loading)
        ? (_openBlock(), _createBlock(_component_b_spinner, { key: 0 }))
        : (_openBlock(), _createBlock(_component_b_tabs, {
            key: 1,
            class: "role-tabs"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_tab, {
                title: _ctx.getTitleCaseTranslation('core.domain.details')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_orbis_card, {
                    title: _ctx.getTitleCaseTranslation('core.domain.details')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_text_input, {
                                modelValue: _ctx.state.roleEdit.name,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.roleEdit.name) = $event)),
                                autofocus: "",
                                error: _ctx.validationResult.model.name,
                                label: _ctx.getTitleCaseTranslation('core.domain.name')
                              }, null, 8, ["modelValue", "error", "label"]),
                              _createVNode(_component_text_input, {
                                modelValue: _ctx.state.roleEdit.description,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.roleEdit.description) = $event)),
                                error: _ctx.validationResult.model.description,
                                label: _ctx.getTitleCaseTranslation('core.domain.description')
                              }, null, 8, ["modelValue", "error", "label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_b_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_text_input, {
                                "model-value": _ctx.getUserCountForRole(),
                                disabled: "",
                                label: _ctx.getTitleCaseTranslation('core.common.usersInRole')
                              }, null, 8, ["model-value", "label"]),
                              _createElementVNode("p", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.namesInRole')), 1),
                              _createElementVNode("table", _hoisted_1, [
                                _createElementVNode("tbody", null, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getUsersInRole(), (user) => {
                                    return (_openBlock(), _createElementBlock("tr", null, [
                                      _createElementVNode("td", null, _toDisplayString(user), 1)
                                    ]))
                                  }), 256))
                                ])
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["title"])
                ]),
                _: 1
              }, 8, ["title"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.availableModules, (appModule) => {
                return (_openBlock(), _createBlock(_component_b_tab, {
                  key: appModule.id,
                  title: appModule.name
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_orbis_card, {
                      title: _ctx.getTitleCaseTranslation('core.domain.rolePermissions')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_col, null, {
                              default: _withCtx(() => [
                                _createElementVNode("dl", null, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(appModule.modulePermissions, (permission) => {
                                    return (_openBlock(), _createBlock(_component_b_form_checkbox, {
                                      key: permission.id,
                                      modelValue: permission.selected,
                                      "onUpdate:modelValue": ($event: any) => ((permission.selected) = $event)
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("dt", null, _toDisplayString(permission.name), 1),
                                        _createElementVNode("dd", null, _toDisplayString(permission.description), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue"]))
                                  }), 128))
                                ])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_b_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_col, { class: "selector-button-row" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_b_button, {
                                  variant: "primary",
                                  onClick: ($event: any) => (_ctx.allSelector(appModule.id))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.selectAll')), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]),
                                _createVNode(_component_b_button, {
                                  variant: "secondary",
                                  onClick: ($event: any) => (_ctx.noneSelector(appModule.id))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.common.clear')), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["title"])
                  ]),
                  _: 2
                }, 1032, ["title"]))
              }), 128))
            ]),
            _: 1
          }))
    ]),
    _: 1
  }, 8, ["title", "go-back"]))
}