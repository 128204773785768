import Permission from './Permission';

export default class ApplicationModule {
    constructor(init?: Partial<ApplicationModule>) {
        Object.assign(this, init);
    }

    public id?: number;

    public name?: string;

    public description?: string;

    public modulePermissions: Array<Permission> = new Array<Permission>();
}
