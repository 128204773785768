import Role from '@/domain/Role';
import RoleApiService from './api/RoleApiService';
import { useNotification } from '@/composable/useNotifications';
import { getTranslation } from './TranslationService';

export default class PermissionService {
    private apiService: RoleApiService;

    private notification = useNotification();

    constructor() {
        this.apiService = new RoleApiService();
    }

    public async getAllRoles(): Promise<{ roles: Array<Role>; success: boolean }> {
        const response = await this.apiService.getAllRoles();
        if (!response) {
            this.notification.showError(getTranslation('core.validation.unableToFetchRoles'));
        }
        return {
            roles: response.data,
            success: response.success,
        };
    }

    public async getRole(id: number): Promise<{ role: Role; success: boolean }> {
        const response = await this.apiService.getRole(id);
        if (!response) {
            this.notification.showError(getTranslation('core.validation.unableToFetchRoles'));
        }
        return {
            role: response.data,
            success: response.success,
        };
    }

    public async addRole(role: Role): Promise<{ role: Role; success: boolean }> {
        const response = await this.apiService.addRole(role);
        if (!response) {
            this.notification.showError(getTranslation('core.validation.unableToUpdateRole'));
        }
        return {
            role: response.data,
            success: response.success,
        };
    }

    public async updateRole(role: Role): Promise<{ role: Role; success: boolean }> {
        const response = await this.apiService.updateRole(role);
        if (!response) {
            this.notification.showError(getTranslation('core.validation.unableToUpdateRole'));
        }
        return {
            role: response.data,
            success: response.success,
        };
    }
}
