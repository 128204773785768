import Axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import Role from '@/domain/Role';
import { DataListAccessResponseClass, DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class RoleApiService {
    public async getAllRoles(): Promise<DataAccessResponse<Array<Role>>> {
        const response: AxiosResponse<DataAccessResponse<Array<Role>>> = await Axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/roles`,
        });
        return new DataListAccessResponseClass<Role>(response, Role).response;
    }

    public async getRole(id: number): Promise<DataAccessResponse<Role>> {
        const response: AxiosResponse<DataAccessResponse<Role>> = await Axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/roles/${id}`,
        });
        return new DataAccessResponseClass<Role>(response, Role).response;
    }

    public async addRole(role: Role) {
        const response: AxiosResponse<DataAccessResponse<Role>> = await Axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/roles`,
            data: role,
        });
        return new DataAccessResponseClass<Role>(response, Role).response;
    }

    public async updateRole(role: Role) {
        const response: AxiosResponse<DataAccessResponse<Role>> = await Axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/roles`,
            data: role,
        });
        return new DataAccessResponseClass<Role>(response, Role).response;
    }
}
