
import {
    defineComponent, onBeforeMount, reactive, computed,
} from 'vue';
import Screen from '@/components/layout/Screen.vue';
import UserRoleService from '@/services/RoleService';
import Role from '@/domain/Role';
import PermissionService from '@/services/PermissionService';
import ApplicationModule from '@/domain/ApplicationModule';
import BRow from '@/components/bootstrap-library/BRow.vue';
import Permission from '@/domain/Permission';
import BButton from '@/components/bootstrap-library/BButton.vue';
import { useNotification } from '@/composable/useNotifications';
import useValidator from '@/validation/useValidator';
import router from '@/router';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import BTab from '@/components/bootstrap-library/BTab.vue';
import BTabs from '@/components/bootstrap-library/BTabs.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';

type State = {
    roleEdit: Role;
    loading: boolean;
    availableModules: Array<ApplicationModule>;
    saving: boolean;
    originalRole: string;
};

export default defineComponent({
    name: 'user-roles-edit',
    components: {
        Screen,
        BRow,
        BButton,
        BTab,
        BTabs,
        SmartTrakFooter,
        TextInput,
        BSpinner,
    },
    props: {
        roleId: {
            required: false,
            type: String,
            default: () => '',
        },
    },
    setup(props) {
        const state = reactive<State>({
            roleEdit: new Role(),
            loading: true,
            availableModules: [],
            saving: false,
            originalRole: '',
        });

        const roleService = new UserRoleService();
        const permissionService = new PermissionService();
        const notification = useNotification();
        const { confirm } = useDialogBox();

        const { validateForm, validationResult } = useValidator<Role>('role');

        const pageTitle = computed(() => (state.roleEdit.id ? getTitleCaseTranslation('core.button.editRole') : getTitleCaseTranslation('core.button.addRole')));

        async function fetchModules() {
            const response = await permissionService.getAllModulesAndPermissions();
            if (response.success) {
                state.availableModules = response.modules;
            }
        }

        async function fetchRole() {
            if (!props.roleId) {
                return;
            }
            const response = await roleService.getRole(parseInt(props.roleId, 10));
            if (response.success) {
                state.roleEdit = response.role;
            }
        }

        async function initAvailablePermissions() {
            const permissions: Permission[] = [];
            state.availableModules.forEach((appModule) => {
                appModule.modulePermissions.forEach((permission) => {
                    permission.selected = state.roleEdit.rolePermissions.some((x) => x.id === permission.id);
                    permissions.push(permission);
                });
                appModule.modulePermissions.sort((a, b) => (a.name > b.name ? 1 : -1));
            });
        }

        function mapPermissionsToRole() {
            state.roleEdit.rolePermissions = [];
            state.availableModules.forEach((appModule) => {
                state.roleEdit.rolePermissions.push(...appModule.modulePermissions.filter((permission) => permission.selected));
            });
        }

        onBeforeMount(async () => {
            await fetchRole();
            await fetchModules();
            await initAvailablePermissions();

            // make sure permissions in the role are sorted by module/name so the isDirty JSON comparison works correctly
            mapPermissionsToRole();
            state.originalRole = JSON.stringify(state.roleEdit);

            state.loading = false;
        });

        async function goToRoleList() {
            // make sure any permission changes are mapped into the role before checking if dirty
            mapPermissionsToRole();

            let confirmed = true;
            if (JSON.stringify(state.roleEdit) !== state.originalRole) {
                confirmed = await confirm({
                    title: getTitleCaseTranslation('core.common.areYouSure'),
                    message: getTranslation('core.common.allUnsavedDataWillBeLostWhenYouLeaveThisPage'),
                });
            }

            if (confirmed) {
                router.push({ name: MasterDataRouteTypes.ROLES.LIST });
            }
        }

        async function save() {
            mapPermissionsToRole();

            if (state.roleEdit.rolePermissions.length === 0) {
                notification.showError(getTranslation('core.validation.roleMustHaveAtLeast1PermissionAssigned'));
            }

            validateForm(state.roleEdit);
            if (validationResult.isValid) {
                state.saving = true;
                const resp = state.roleEdit.id ? await roleService.updateRole(state.roleEdit) : await roleService.addRole(state.roleEdit);
                if (resp.success) {
                    router.push({ name: MasterDataRouteTypes.ROLES.LIST });
                }
                state.saving = false;
            }
        }

        function getUserCountForRole() {
            return state.roleEdit.userCount ?? 0;
        }

        function getUsersInRole() {
            return state.roleEdit.users ?? '';
        }

        function allSelector(appModuleId?: number) {
            const permissionsToAddToRole = state.availableModules.find((appModule) => appModule.id === appModuleId)?.modulePermissions;
            permissionsToAddToRole?.forEach((permission) => {
                permission.selected = true;

                const matchedPermission = state.roleEdit.rolePermissions?.find((x) => x.id === permission.id);
                if (!matchedPermission) {
                    state.roleEdit.rolePermissions.push({ ...permission });
                }
            });
        }

        function noneSelector(appModuleId?: number) {
            const permissionsToRemoveFromRole = state.availableModules.find((appModule) => appModule.id === appModuleId)?.modulePermissions;
            if (permissionsToRemoveFromRole) {
                permissionsToRemoveFromRole.forEach((permission) => {
                    permission.selected = false;
                    state.roleEdit.rolePermissions = state.roleEdit.rolePermissions?.filter((x) => x.id !== permission.id) ?? [];
                });
            }
        }

        return {
            state,
            save,
            allSelector,
            noneSelector,
            getTitleCaseTranslation,
            getTranslation,
            getUserCountForRole,
            getUsersInRole,
            validationResult,
            pageTitle,
            goToRoleList,
        };
    },
});
