import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-140a0878"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs-container" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, ({tab, active, disabled}, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "tab-container",
          style: _normalizeStyle({width: `${100/_ctx.numOfTabs}%`})
        }, [
          _createElementVNode("div", {
            onClick: () => _ctx.handleTabClick(i)
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(tab), {
              active: active,
              disabled: disabled,
              tab: "",
              style: {"height":"100%"}
            }, null, 8, ["active", "disabled"]))
          ], 8, _hoisted_2)
        ], 4))
      }), 128))
    ]),
    _createElementVNode("div", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.activeContent)))
    ])
  ]))
}