import { useNotification } from '@/composable/useNotifications';
import ApplicationModule from '@/domain/ApplicationModule';
import PermissionApiService from './api/PermissioinApiService';

export default class PermissionService {
    private apiService: PermissionApiService;

    private notification = useNotification();

    constructor() {
        this.apiService = new PermissionApiService();
    }

    public async getAllModulesAndPermissions(): Promise<{ modules: Array<ApplicationModule>, success: boolean }> {
        const response = await this.apiService.getAllModulesAndPermissions();
        if (!response) {
            this.notification.showError('Unable to fetch application permissions');
        }

        return {
            modules: response.data,
            success: response.success,
        };
    }
}
