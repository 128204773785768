
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'b-tab',
    props: {
        title: String,
        active: Boolean,
        tab: { type: Boolean, default: () => false },
        badge: String,
    },
});
