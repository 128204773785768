import ApplicationUser from './ApplicationUser';
import Permission from './Permission';

export default class Role {
    constructor(init?: Partial<Role>) {
        this.id = init?.id;
        this.name = init?.name;
        this.description = init?.description;
        this.users = init?.users;
        this.rolePermissions = init?.rolePermissions || [];
        this.userCount = init?.userCount;
    }

    public id?: number;

    public name?: string;

    public description?: string;

    public userCount?: number;

    public users?: Array<string>;

    public rolePermissions: Array<Permission> = [];
}
