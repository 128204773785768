import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e0b4db2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tab-badge"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(['tab', {'tab-active bg-primary': _ctx.active}])
    }, [
      _renderSlot(_ctx.$slots, "tab", {}, () => [
        _createElementVNode("span", null, _toDisplayString(_ctx.title), 1),
        (_ctx.badge)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.badge), 1))
          : _createCommentVNode("", true)
      ])
    ], 2),
    (!_ctx.tab)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ]))
}