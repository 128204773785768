import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import ApplicationModule from '@/domain/ApplicationModule';
import { DataListAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class PermissionApiService {
    public async getAllModulesAndPermissions(): Promise<DataAccessResponse<Array<ApplicationModule>>> {
        const response: AxiosResponse<DataAccessResponse<Array<ApplicationModule>>> = await axios({
            method: 'get',
            url: `${API_SERVICE_URL}/permissions`,
        });
        return new DataListAccessResponseClass<ApplicationModule>(response, ApplicationModule).response;
    }
}
